
import { Component, Vue, Watch } from "vue-property-decorator";
import DriveForm from "@/views/drivers/driver-form/DriveForm.vue";

import { DriversList } from "@/services/api/ApiDrivers";
import { StatusCarTypes } from "@/services/api/ApiCar";
import { IStatusCarListRequest } from "@/services/models/car";
import { IDriversListRequest, IDriver } from "@/services/models/driver/index";

import { notificationError } from "@/utils/NotificationUtils";

@Component({
  components: {
    DriveForm,
 
  }
})
export default class Drivers extends Vue {
  loading = false;
  openModal = false;
  items: IDriver[] = [];
  driver_id: number | null = null;
  statusOptions: IStatusCarListRequest[] = [];
  totalRows = 1;
  currentPage = 1;
  perPage = 10;
  pages = 1;
  pageOptions: number[] = [10, 25, 50, 100];
  filter = "";
  countPage = 0;
  fields: { key: string; label: string }[] = [
    {
      key: "full_name",
      label: `${this.$t("drivers.fields.full_name")}`
    },

    {
      key: "license_types",
      label: `${this.$t("drivers.fields.license_type")}`
    },
    {
      key: "status",
      label: `${this.$t("drivers.fields.status")}`
    },

    {
      key: "actions",
      label: `${this.$t("drivers.fields.actions")}`
    }
  ];

  @Watch("perPage")
  onPerpageChange() {
    this.driverList();
  }

  @Watch("currentPage")
  onCurrentPageChange() {
    this.driverList();
  }

  async mounted(): Promise<void> {
    await this.driverList();
    this.totalRows = this.items.length;
    await this.driverStatus();
  }

  async driverList(): Promise<void> {
    this.loading = true;
    try {
      let response = await DriversList(this.currentPage, this.perPage);
      this.items = response.data;
      this.totalRows = response.total;
      this.pages = response.last_page;
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  }
  async driverStatus(): Promise<void> {
    try {
      let status = await StatusCarTypes();
      this.statusOptions = status;
    } catch (error) {
      console.log(error);
    }
  }
  showLicense(license: Array<string>): string {
    return license.sort().join(" - ");
  }

  showModal(): void {
    this.openModal = true;
    this.driver_id = null;
  }

  closeModal(): void {
    this.openModal = false;
  }

  async showEditModal(id: number): Promise<void> {
    try {
      this.driver_id = id;
      this.openModal = true;
      console.log(id);
    } catch (error) {
      console.error(error);
      notificationError(
        `${this.$t("drivers.messages.error.message.searchUser")}`
      );
    }
  }

  showPages(): number {
    return Math.ceil(this.totalRows / this.perPage);
  }

  onFiltered(filteredItems: IDriversListRequest[]): void {
    if (filteredItems.length > 0) {
      this.countPage = Math.ceil(this.totalRows / this.perPage);
    } else {
      this.countPage = 1;
    }
  }

  reloadPage(): void {
    this.driverList();
  }
}
