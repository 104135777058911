import {
  IDriversShowOneRequest,
  IDriversPostRequest,
  IDriver
} from "../models/driver";
import { ILicenseTypes } from "../models";

export const DriverListToPostRequest = (
  car: IDriversShowOneRequest
): IDriversPostRequest => {
  const newLicenseTypes = car.license_types.map((item: ILicenseTypes) => {
    return item.id;
  });
  const formatedCar = {
    user_id: car.id,
    driving_license_types: newLicenseTypes,
    driving_license: car.driving_license
  };
  return formatedCar as IDriversPostRequest;
};

export const TrasformDriverShowOneToIDriver = (
  driver: IDriversShowOneRequest
): IDriver => {
  const driverTransform: IDriver = {
    id: driver.id,
    user_id: driver.driver.id,
    full_name: `${driver.driver.name} ${driver.driver.surname}`,
    status: driver.status.name,
    driver_status_id: driver.status.id,
    license_types: driver.license_types
  };

  return driverTransform;
};
