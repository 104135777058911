
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { required } from "vuelidate/lib/validators";
import { DriverListToPostRequest } from "@/services/transform/DriverDataTransform";

import {
  notificationSuccess,
  notificationError,
} from "@/utils/NotificationUtils";

import { LicenseTypesList } from "@/services/api/ApiCar";
import {} from "@/services/models/car";
import { ILicenseTypes, IUserListRequest } from "@/services/models";

import {
  DriverInsertRequest,
  DriverUpdateRequest,
  DriverFetchOneRequest,
} from "@/services/api/ApiDrivers";
import { IDriversPostRequest } from "@/services/models/driver";
import { UserList } from "@/services/api/ApiUser";

@Component({
  validations: {
    form: {
      user_id: { required },
      driving_license: { required },
      driving_license_types: { required },
    },
  },
})
export default class CarFom extends Vue {
  @Prop() open_modal!: boolean;
  @Prop() driver_id!: number;

  loading = false;
  licenseTypes: ILicenseTypes[] = [];
  usersList: IUserListRequest[] = [];
  form: IDriversPostRequest = {
    user_id: undefined,
    driving_license: "",
    driving_license_types: [],
  };

  async requestLicenseTypesList(): Promise<void> {
    let licenseTypesList = await LicenseTypesList();
    this.licenseTypes = licenseTypesList;
  }

  close(): void {
    this.$emit("close_modal");
    this.$v.$reset();
    if (!this.driver_id) {
      this.reset();
    }
  }

  async edit(): Promise<void> {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      try {
        if (this.form.user_id) {
          console.log(this.form);

          let data = {
            ...this.form,
          };

          await DriverUpdateRequest(this.form.user_id, data);
          this.close();
          notificationSuccess(
            `${this.$t("drivers.messages.success.message.update")}`
          );
          this.$emit("reload-page");
        }
      } catch (error) {
        console.error(error);
        notificationError(
          `${this.$t("drivers.messages.error.message.update")}`
        );
      }
    }
  }
  async listUsers(): Promise<void> {
    const users = await UserList();
    this.usersList = users;
  }
  async mounted(): Promise<void> {
    this.listUsers();
  }

  async save(): Promise<void> {
    this.$v.$touch();

    if (!this.$v.$invalid) {
      try {
        let data = {
          ...this.form,
        };
        await DriverInsertRequest(data);
        this.close();
        notificationSuccess(
          `${this.$t("drivers.messages.success.message.create")}`
        );
        this.$emit("reload-page");
      } catch (error) {
        console.error(error);
        notificationError(
          `${this.$t("drivers.messages.error.message.create")}`
        );
      }
    }
  }

  reset(): void {
    this.form = {
      user_id: undefined,
      driving_license: "",
      driving_license_types: [],
    };
  }

  @Watch("driver_id")
  async User(): Promise<void> {
    if (this.driver_id) {
      this.loading = true;
      let car = await DriverFetchOneRequest(this.driver_id);
      let driverFormated = DriverListToPostRequest(car);
      this.form = driverFormated;
      this.loading = false;
    } else {
      this.reset();
    }
  }

  @Watch("open_modal", { deep: true })
  async openModal(): Promise<void> {
    // console.log(this.brandList);
    if (this.open_modal) {
      await this.requestLicenseTypesList();
    } else {
      this.licenseTypes = [];
      // this.optionsState = [];
    }
  }
}
