import {
  IDriversListRequest,
  IDriversPostRequest,
  IDriversShowOneRequest,
  IDriversUpdateRequest
} from "../models/driver";
import { IUserStatus } from "../models";
import ApiBase from "./ApiBase";

const USER_PATH = "/dash";
const ADMIN = "/admin";
const DRIVERS = "/drivers";
const USERS = "/users";
const WITHOUTITINERARIES = "/withoutitineraries";
/**
 * Exibe os Motorista
 * @returns { IDriversListRequest } - Lista os Motoristas
 */
export const DriversList = async (
  page: number,
  offset: number
): Promise<IDriversListRequest> => {
  const res = await ApiBase.get(
    `${USER_PATH}${ADMIN}${DRIVERS}?offset=${offset}&page=${page}`
  );
  return res?.data as IDriversListRequest;
};

/**
 * Exibe os Motorista sem itinerarios
 * @returns { IDriversListRequest } - Lista os Motoristas
 */
export const DriversListWithoutitineraries = async (
  page: number,
  offset: number
): Promise<IDriversListRequest> => {
  const res = await ApiBase.get(
    `${USER_PATH}${ADMIN}${DRIVERS}${WITHOUTITINERARIES}?offset=${offset}&page=${page}`
  );
  return res?.data as IDriversListRequest;
};

/**
 * Mostra os Status de usuarios
 * @returns { IUserStatus } - Lista de Status de usuario
 */
export const UserStatus = async (): Promise<IUserStatus[] | []> => {
  const res = await ApiBase.get(`${USER_PATH}${ADMIN}${USERS}/status`);
  return res?.data as IUserStatus[];
};

/**
 * Insere dados do motorista
 * @param { IDriversPostRequest } driver - Dados para adicionar
 * @returns { IDriversPostRequest } - Morotista adicionado
 */
export const DriverInsertRequest = async (
  driver: IDriversPostRequest
): Promise<IDriversPostRequest> => {
  const res = await ApiBase.post(`${USER_PATH}${ADMIN}${DRIVERS}`, driver);
  return res?.data as IDriversPostRequest;
};

/**
 * Seleciona o Motorista de acordo com o id informado
 * @param { number } id - Id do Motorista para seleção
 * @returns { IDriversShowOneRequest } - Motorista com o id informado
 */
export const DriverFetchOneRequest = async (
  id: number
): Promise<IDriversShowOneRequest> => {
  const res = await ApiBase.get(`${USER_PATH}${ADMIN}${DRIVERS}/${id}`);
  return res?.data as IDriversShowOneRequest;
};

/**
 * Altera dados do motorista de acordo com o id informado
 * @param { number } id - Id do motorista para edição
 * @param { IDriversPostRequest } driver - Dados do motorista
 * @returns { IDriversPostRequest } - Dados do motorista alterado
 */
export const DriverUpdateRequest = async (
  id: number,
  driver: IDriversPostRequest
): Promise<IDriversPostRequest> => {
  console.log(id);
  console.log(driver);
  const res = await ApiBase.put(`${USER_PATH}${ADMIN}${DRIVERS}/${id}`, driver);
  return res?.data as IDriversPostRequest;
};
