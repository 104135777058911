var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"title":_vm.form.user_id
      ? ("" + (_vm.$t('drivers.modal.form.title.update')))
      : ("" + (_vm.$t('drivers.modal.form.title.create'))),"no-close-on-esc":"","no-close-on-backdrop":"","hide-header-close":"","footer-class":"d-flex justify-content-between","size":"lg"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t("drivers.modal.form.cancel"))+" ")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){_vm.driver_id ? _vm.edit() : _vm.save()}}},[_vm._v(" "+_vm._s(_vm.$t("drivers.modal.form.save"))+" ")])]},proxy:true}]),model:{value:(_vm.open_modal),callback:function ($$v) {_vm.open_modal=$$v},expression:"open_modal"}},[_c('b-overlay',{attrs:{"show":_vm.loading,"rounded":"sm"}},[_c('b-form',[_c('b-form-row',[_c('b-col',[_c('b-row',[_c('b-col',{attrs:{"sm":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('drivers.fields.role'),"label-class":"pb-0"}},[_c('b-form-select',{attrs:{"size":"sm","options":_vm.usersList,"value-field":"id","text-field":"full_name","state":!_vm.$v.form.user_id.required && _vm.$v.form.user_id.$dirty
                      ? false
                      : null},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":_vm.form.user_id === undefined ? undefined : _vm.form.id,"disabled":true}},[_vm._v(" "+_vm._s(_vm.usersList.length > 0 ? _vm.$t("drivers.modal.form.option") : _vm.$t("drivers.modal.form.loading"))+" ")])]},proxy:true}]),model:{value:(_vm.form.user_id),callback:function ($$v) {_vm.$set(_vm.form, "user_id", $$v)},expression:"form.user_id"}})],1)],1),_c('b-col',{attrs:{"sm":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('drivers.fields.driving_license_number'),"label-class":"pb-0"}},[_c('b-form-input',{class:!_vm.$v.form.driving_license.required &&
                    _vm.$v.form.driving_license.$dirty
                      ? 'is-invalid'
                      : '',attrs:{"size":"sm","type":"number","trim":""},model:{value:(_vm.form.driving_license),callback:function ($$v) {_vm.$set(_vm.form, "driving_license", $$v)},expression:"form.driving_license"}})],1)],1),_c('b-col',{attrs:{"sm":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('drivers.fields.driving_license_type'),"label-class":"pb-0"}},[_c('b-overlay',{attrs:{"show":_vm.licenseTypes.length === 0,"rounded":"sm","spinner-small":""}},[_c('b-form-checkbox-group',{attrs:{"id":"checkbox-group-1","value-field":"id","text-field":"name","options":_vm.licenseTypes,"state":!_vm.$v.form.driving_license_types.required &&
                      _vm.$v.form.driving_license_types.$dirty
                        ? false
                        : null},model:{value:(_vm.form.driving_license_types),callback:function ($$v) {_vm.$set(_vm.form, "driving_license_types", $$v)},expression:"form.driving_license_types"}})],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }